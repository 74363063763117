/* base font */

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap'); /* box sizing everywhere */

html, input, button, textarea {
    font-family: 'Fira Sans', sans-serif;
    font-size: 12pt;
}

/* box sizing everywhere */

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body, #algolab {
    height: 100%;
    margin: 0;
}

body {
    padding: 10px;
}

#algolab {
    display: flex;
    flex-direction: column;
}

#mainArea {
    flex-grow: 1;
    display: flex;
}

#leftArea {
    margin-right: 10px;
}

#actionArea {
    width: 400px;
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    gap: 0.5em;
}

button {
    padding: 0.5em;
    border: solid 3px #7f8fa9;
    border-radius: 0.5em;
    background: #7f8fa9;
    color: white;
    font-size: 110%;
}

button.primary {
    background-color: #297FD5;
    border-color: #297FD5;
}

button:hover {
    color: #7f8fa9;
    background-color: white;
}

button.primary:hover {
    color: #297FD5;
    background-color: white;
}

#blocklyArea {
    flex-grow: 1;
}

#bottomArea {
    margin: 10px 0;
}
