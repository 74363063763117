@import "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap";
/* base font */

/* box sizing everywhere */

html, input, button, textarea {
    font-family: 'Fira Sans', sans-serif;
    font-size: 12pt;
}

/* box sizing everywhere */

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html, body, #algolab {
    height: 100%;
    margin: 0;
}

body {
    padding: 10px;
}

#algolab {
    display: flex;
    flex-direction: column;
}

#mainArea {
    flex-grow: 1;
    display: flex;
}

#leftArea {
    margin-right: 10px;
}

#actionArea {
    width: 400px;
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    gap: 0.5em;
}

button {
    padding: 0.5em;
    border: solid 3px #7f8fa9;
    border-radius: 0.5em;
    background: #7f8fa9;
    color: white;
    font-size: 110%;
}

button.primary {
    background-color: #297FD5;
    border-color: #297FD5;
}

button:hover {
    color: #7f8fa9;
    background-color: white;
}

button.primary:hover {
    color: #297FD5;
    background-color: white;
}

#blocklyArea {
    flex-grow: 1;
}

#bottomArea {
    margin: 10px 0;
}


svg[data-v-7c87b4] {
  background: #eee;
}
.status[data-v-7c87b4] {
  fill: none;
  stroke: none;
  stroke-width: 5;
}
.status .border[data-v-7c87b4] {
  fill: none;
  stroke: none;
  stroke-width: 5;
}
.status.complete .border[data-v-7c87b4] {
  stroke: forestgreen;
}
.status.failed .border[data-v-7c87b4] {
  stroke: darkred;
}
.room-square[data-v-7c87b4] {
  stroke: #888;
  stroke-width: 0.01;
}
.room-square.dirty[data-v-7c87b4] {
  fill: #ccc;
}
.room-square.clean[data-v-7c87b4] {
  fill: #fff;
}
.room-square.thumb[data-v-7c87b4] {
  stroke-width: 0.03;
}
.robot[data-v-7c87b4] {
  transform: translate(var(--x), var(--y)) rotate(var(--angle));
  transition: transform 0.2s ease;
}


.situation-thumbs[data-v-12d687] {
  display: flex;
  gap: 5px;
}


ul[data-v-014115] {
    margin: 0 0 0.5em 0;
    padding: 0;
    display: flex;
    gap: 0.5em;
    list-style: none;
}
a[data-v-014115] {
    display: block;
    box-sizing: content-box;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0.5rem;
    border-radius: 3rem;
    font-size: 1.3em;
    background: #eee;
    text-align: center;
    color: black;
    text-decoration: none;
}
a[data-v-014115]:hover {
    background: #7f8fa9;
    color: white;
}
.current a[data-v-014115] {
    background: #297FD5;
    color: white;
}

/*# sourceMappingURL=index.8e94c5bc.css.map */
